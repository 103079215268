module services {
    export module master {
        export class vatRateService implements interfaces.master.IVatRateService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all pack Configuration for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "VatRate/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            getVatRate(): ng.resource.IResourceClass<interfaces.master.IVatRate> {

                return this.$resource<interfaces.master.IVatRate>(this.ENV.DSP_URL + "VatRate/GetVatRate", {
                    ownerEntityId: '@mvrId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("vatRateService", services.master.vatRateService);
}